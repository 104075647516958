
import { PATH } from "../utils/constants";
import { instance } from "./instance";

export class SingupHandler {
  static async SingupRequestOTP(mobileNumber: string, countryCode: string) {
    const response = await instance.post(`/${PATH?.AUTH}/${PATH?.SINGUP}`, { mobileNumber, countryCode });
    return response
  };

  static async SingupRegistration(mobileNumber: string, countryCode: string, OTP: string, password: string, confirmPassword: string, deviceId: string, atag: string, clickId: string) {
    const response = await instance.post(`/${PATH?.AUTH}/${PATH?.REGISTR}`, { mobileNumber, countryCode, OTP, password, confirmPassword, deviceId, atag, clickId });
    return response
  };

  static async ResendOTP(mobileNumber: string, countryCode: string) {
    const response = await instance.post(`/${PATH.AUTH}/${PATH.RESENDOTP}`, { mobileNumber, countryCode });
    return response
  };

}
