import { useEffect, useState, useRef, memo } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic"
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert"
import { Snackbar } from "@material-ui/core"
import SideAccount from "./SideAccount"
import { initSideMenuOthers } from "../../redux/menu/sideMenuOthers"
import { GeneralHandler } from "../../api/general"
import { initMenuList } from "../../redux/menu/headerMenu"
import { initSideMenu } from "../../redux/menu/sideMenuSports"
import { initSideMenuGames } from "../../redux/menu/sideMenuGames"
import IconMenu from "../../static/images/header/menu.svg"
import IconUser from "../../static/images/header/user.svg"
import rulesImg from "../../static/images/header/rules.svg"
import { JiliPopUp } from "../CasinoJILI_popUp"
import { setIsOpenSuccess } from "../../redux/UI/betSuccess.slice"
import { initErrMsg } from "../../redux/UI/betErrMsg"
import { GlobalRulesPopUp } from "../GlobalRules_popUp"
import CustomButton from "../UI/Button"
import { setHeaderTab } from "../../redux/UI/headerTab.slice"
import { Search } from "./Search"
import SearchIcon from "./Search/icons/searchMobile.svg"
import closeIcon from "./Search/icons/closeButton.svg"
import { LoginHandler } from "../../api/login"
// import ReCAPTCHA from "react-google-recaptcha"
import { UpdatePopUp } from "../../pages/Login/components/UpdatePopUp"
import SignupPopUp from "../SignupPopUp/SingupPopUp"
import LoginPopUp from "../LoginPopUp/LoginPopUp"
import { initGoogleCfg } from "../../redux/menu/googleCfg"
import ForgotPassword from "../ForgotPassword/ForgotPassword"
import BonusPopUp from "../../pages/User/BonusPage/BonusPopUp"
import { setVersion, userBonusSlice } from "../../redux/menu/userBonus.slice"
import { BalanceNew } from "./BalanceNew"
import TimeSettings from "./TimeSettings"
import mainLogoAnimation from '../../static/lottie/mainLogoAnimation.json'
// import independedLogo from '../../static/lottie/independedLogo.json'
import lottie from "lottie-web"
import { NotificationPopUp } from "./NotificationPopUp"
import { BetSlip } from "../../pages/SportsEventDetails/Betslip"
import Ticker from "../Ticker/Ticker"
import "./styles/index.scss"
import {
  setFailPopUp, setGlobalRules,
  setIsOpenLoginPopUp,
  setIsOpenSideBar,
  setIsOpenSignupPopUp
} from "../../redux/UI/openPopUps.slice"
import { DynamicPageLoader, ReducersList } from "../../redux/DynamicPageLoader"

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

type Components = "" | "sideMenu" | "sideAccount" | "balance" | "actions"

const pageReducers: ReducersList = {
  "userBonus": userBonusSlice.reducer,
};

const HeaderNew = memo(() => {
  const history = useHistory()
  const dispatch = useDispatch()
  const {
    user: { isAuthenticated }
  } = useSelector((state: IRootState) => ({ user: state.user }))
  const [search, setSearch] = useState(false)
  const [isB2C, setisB2C] = useState(false)

  // betslip alert logic
  const success = useSelector((state: IRootState) => state.success.isOpenSuccess)
  const fail = useSelector((state: IRootState) => state.openPopUps.fail)
  const errMsg = useSelector((state: IRootState) => state.errMsg.errMsg)

  const lottieLogoContainer = useRef<HTMLDivElement | null>(null);

  const handleCloseAlert = () => {
    dispatch(setIsOpenSuccess(false))
    dispatch(setFailPopUp({isOpenFail: false}));
    dispatch(initErrMsg({ errMsg: "" }))
  }

  const [showComponent, setShowComponent] = useState<Components>("")

  const onChangeShowComponent = (name: Components) => {
    setShowComponent(prev => {
      return prev === name ? "" : name
    })
  }

  const initData = () => {
    GeneralHandler.getMenuList().then(list => {
      const headerMenu = list.main_heading
      const sideMenu = list.general
      const others = list.others
      dispatch(initMenuList(headerMenu))
      dispatch(initSideMenu(sideMenu))
      dispatch(initSideMenuOthers(others))
      if (list.popular) {
        const sideMenuGames = list.popular
        dispatch(initSideMenuGames(sideMenuGames))
      }
    })
  }

  useEffect(() => {
    if (lottieLogoContainer.current) {
      const animation = lottie.loadAnimation({
        container: lottieLogoContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: mainLogoAnimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        }
      });
      return () => animation.destroy();
    } else {
      return
    }
  }, []);

  useEffect(() => {
  
    initData()

    const menu = isAuthenticated ? setIntervalAsync(async () => {
      await GeneralHandler.getMenuList().then(list => {
        const headerMenu = list.main_heading
        const sideMenu = list.general
        const others = list.others
        dispatch(initMenuList(headerMenu))
        dispatch(initSideMenu(sideMenu))
        dispatch(initSideMenuOthers(others))
        if (list.popular) {
          const sideMenuGames = list.popular
          dispatch(initSideMenuGames(sideMenuGames))
        }
      })
    }, 30000) : null
    
    return () => {
      if (menu) {
        clearIntervalAsync(menu)
      }
    }
  }, [])

  const [width, setWidth] = useState(window.innerWidth)
  const handleResize = () => setWidth(window.innerWidth)

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  // Demo UserLogin
  // const [recaptchaSiteKey, setRecaptchaSiteKey] = useState("")
  // const recaptchaRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const isOpenJiliPopUp = useSelector((state: IRootState) => state.openPopUps.isOpenPopUp)
  const isOpenRulesPopUp = useSelector((state: IRootState) => state.openPopUps.isOpenPopUp)
  const isOpenSingupPopUp = useSelector((state: IRootState) => state.openPopUps.isOpenSignupPopUp)
  const isOpenLoginPopUp = useSelector((state: IRootState) => state.openPopUps.isOpenLoginPopUp)
  const isOpenForgotPasswordPopUp = useSelector((state: IRootState) => state.openPopUps.isOpenForgotPasswordPopUp)
  const userBonus = useSelector((state: IRootState) => state?.userBonus)
  const { notificationPopUp } = useSelector((state: IRootState) => state.notification)

  useEffect(() => {

    LoginHandler.googleConfig().then(res => {
      // setRecaptchaSiteKey(res.recaptchaConfig.recaptchaSiteKey)
      setisB2C(res.isB2C)
      dispatch(initGoogleCfg(res))

      const versionNow = res.appConfig["1"].model["dia-version"]

      if (localStorage.getItem("versionNow") === versionNow) {
        return console.log("version up to date")
      } else if (!localStorage.getItem("versionNow")) {
        localStorage.setItem("versionNow", versionNow)
      } else if (localStorage.getItem("versionNow") != versionNow) {
        setIsOpen((isOpen) => !isOpen)
        localStorage.setItem("versionNow", versionNow)
      }
    })
    // eslint-disable-next-line
  }, [])

  const sideMenu = useSelector((state: IRootState) => state.sideMenu)
  // @ts-ignore
  const sportId = history?.location?.state?.sportId
  const getPage = (): string => {
    if (history.location.pathname.includes("FAWK")) return ""
    if (history.location.pathname.includes("card-game/")) return "Card Game"
    if (history.location.pathname.includes("deposit")) return "Deposit"
    if (history.location.pathname.includes("unsettled-bet")) return "Open Bets"
    if (userBonus?.version === "mobile") return "Profile"
    if (history.location.pathname.includes("profit-loss-report")) return "Betting P&L"
    if (history.location.pathname.includes("account-statement")) return "Statement"
    if (history.location.pathname.includes("bonus-statement")) return "Statement"
    if (history.location.pathname.includes("change-password")) return "Change password"
    if (history.location.pathname.includes("set-button-values")) return "Settings"
    if (history.location.pathname.includes("realCash")) return "Settings"
    if (sportId && sideMenu.length !== 0) {
      if (sideMenu.filter(item => item.id === sportId)[0]) {
        return sideMenu.filter(item => item.id === sportId)[0].name
      }
    }

    return ""
  }


  return (
    <DynamicPageLoader removeAfterUnmount reducers={pageReducers}>
      <>
      <UpdatePopUp isOpen={isOpen} />
      {showComponent === "sideAccount" && <SideAccount onChangeShowComponent={onChangeShowComponent} />}
      <header>
        {width > 1024 && width < 1550 && <Ticker />}
        <div className={`header-container ${width <= 1024 && ( history.location.pathname.includes("card-game/") || history.location.pathname.includes("casino-game/")) ? 'bottomHeader' : ''}`}>
          {!search ?
            <>
              <div className="header__left-side">
                {width >= 1024 && !history.location.pathname.includes("deposit") ? (
                  <div className="menu" onClick={() => dispatch(setIsOpenSideBar(true))}>
                    <img src={IconMenu} alt="menu" />
                  </div>
                ): (
                  <>
                    {(!history.location.pathname.includes("home") && !history.location.pathname.includes("sportsbook") || userBonus?.version === "mobile")
                      ? (
                        <div className="menu home" id="backButton" onClick={() => {
                          console.log("!!!!!!!!!!!!!! userBonus?.version", userBonus?.version)
                          if(userBonus?.version === "mobile") {
                            console.log("!!!!!!!!!!!!!!!!!! if")
                            dispatch(setVersion(""))
                          } else if(!history.location.pathname.includes("profile")) {
                            console.log("!!!!!!!!!!!!!!!!!! if else")
                              history.goBack()
                          } else {
                            // history.goBack()
                            console.log("!!!!!!!!!!!!!!!!!! else")
                            history.push("/")
                          }
                          }}
                        />
                      ) : (
                        <div className="menu" onClick={() => dispatch(setIsOpenSideBar(true))}>
                          <img src={IconMenu} alt="menu" />
                        </div>
                    )}
                  </>
                )}

                {getPage() && width < 1024 && width > 340 ?
                  <div className="header__left-side-page"
                       onClick={
                         () => {
                           history.push("/")
                           dispatch(setHeaderTab("IN_PLAY"))
                         }}
                  >
                    {getPage()}
                  </div>
                  :
                  <>
                    <div className="logo-wrapper" onClick={
                      () => {
                        history.push("/")
                        dispatch(setHeaderTab("IN_PLAY"))
                      }}
                      >
                        <div className="logo" />
                      {/* <Lottie options={lottieOptionsIndependedLogo} height={30} width={30} /> */}

                    </div>
                    <div className="android-wrapper">
                      <a href={`https://cdn.mac1j.com/apks/FUNEXCH1.0.3.apk`} target="_blank">
                       <div className="android" />
                      </a>
                    </div>
                    <div onClick={() => {
                      isAuthenticated
                        ? history.push("/specialoffers")
                        : dispatch(setIsOpenLoginPopUp(true))}
                      }
                      ref={lottieLogoContainer} style={{ width: 30, height: 30 }}
                      >
                        {/* <div ref={lottieLogoContainer} style={{ width: 30, height: 30 }} />; */}
                      {/* <Lottie options={lottieOptionsLogo} height={30} width={30} /> */}
                    </div>
                  </>
                }
                <div className="divider" />
                <TimeSettings />
              </div>
              {width > 1550 && <Ticker />}
              <div className="header__right-side">
                {width >= 1024 ?
                  <>
                    <Search />
                    <div className="divider" />
                  </>
                  :
                  <img className="header__right-side-search-img" src={SearchIcon} onClick={() => setSearch(true)}
                       alt="" />
                }
                {isAuthenticated ?
                  <div className="header__right-side-buttons">

                    {localStorage.getItem('userName')?.slice(4, 6) !== 'YO' && <CustomButton
                      className="header__right-side-buttons-button deposit"
                      fullWidth={true}
                      type="submit"
                      onClick={() => history.push("/user/deposit")}
                    >
                      Deposit
                    </CustomButton>}
                    <CustomButton
                      className="header__right-side-buttons-button user"
                      fullWidth={true}
                      type="submit"
                      // onClick={() => width > 1024 ? dispatch(setVersion("sideBar-desktop")) : history.push("/user/profile")}
                      onClick={() => width > 1024 ? dispatch(setVersion("sideBar-desktop")) : dispatch(setVersion("mobile"))}
                    >
                      <div className="header__right-side-user-logo">
                        <img src={IconUser} alt="user" className="user" />
                      </div>
                      <BalanceNew />
                    </CustomButton>
                  </div>
                  :
                  <>
                    <div className="header__right-side-buttons">
                      {width >= 1024 &&
                        <>
                          <div className="header__right-side-buttons-rules-button" onClick={() => {
                            dispatch(setGlobalRules({isOpenPopUp: true}))
                          }}>
                            <img src={rulesImg} alt="rulesImg" />
                          </div>
                        </>}
                      <CustomButton
                        className="header__right-side-buttons-button login"
                        fullWidth={true}
                        type="submit"
                        onClick={() => dispatch(setIsOpenLoginPopUp(true))}
                      >
                        Login
                      </CustomButton>
                      {isB2C && <CustomButton
                        className="header__right-side-buttons-button sign-up"
                        fullWidth={true}
                        type="submit"
                        onClick={() => dispatch(setIsOpenSignupPopUp(true))}
                      >
                        Register
                      </CustomButton>}
                    </div>
                  </>
                }
              </div>
            </>
            :
            <>
              <Search
                openSearch={search}
                closeSearch={setSearch}
                isPhone={true}
              />
              <img className="header-container__slose-search"
                src={closeIcon} onClick={() => setSearch(false)} alt="" />
            </>}
        </div>
        {/*<Ticker/>*/}
      </header>
      {notificationPopUp && <NotificationPopUp />}
      {isOpenJiliPopUp && <JiliPopUp />}
      {isOpenRulesPopUp && <GlobalRulesPopUp />}
      {isOpenSingupPopUp && <SignupPopUp />}
      {isOpenLoginPopUp && <LoginPopUp />}
      {isOpenForgotPasswordPopUp && <ForgotPassword />}
      {(userBonus?.walletType || userBonus?.version) && <BonusPopUp />}

      {success && (
        <Snackbar open={success}
                  className="betslip_message betslip_message-success"
                  onClose={handleCloseAlert}
                  disableWindowBlurListener={true}
                  autoHideDuration={2000}
        >
          <Alert onClose={handleCloseAlert} severity="success">
            Bet Placed Sucessfully
          </Alert>
        </Snackbar>
      )}
      {fail?.isOpenFail && (
        <Snackbar open={fail?.isOpenFail}
                  className="betslip_message betslip_message-error"
                  onClose={handleCloseAlert}
                  disableWindowBlurListener={true}
                  autoHideDuration={6000}
        >
          <Alert onClose={handleCloseAlert} severity="error">
            {errMsg
              ? <div>{errMsg}</div>
              : "Placing bet error. Please try again"
            }
          </Alert>
        </Snackbar>
      )}
      {/* {
        recaptchaSiteKey && !isAuthenticated && (
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={recaptchaSiteKey}
            size="invisible"
          />
        )
      } */}
      {!history.location.pathname.includes('/event') &&
        !history.location.pathname.includes('/sport') &&
          <BetSlip id={''}/>
      }
    </>
    </DynamicPageLoader>
      )
})

export default HeaderNew
