// import ReCAPTCHA from "react-google-recaptcha";
import { PATH } from "../utils/constants";
import { instance } from "./instance";

export class LoginHandler {

  static async googleConfig() {
    const response = await instance.get(`/${PATH.CONFIG}/${PATH.GOOGLE_CONFIG}`);
    return response.data.result
  };
  
  static async LoginRequest(username: string, password: string, deviceId: string) {
    const response = await instance.post(`/${PATH.AUTH}/${PATH.LOGIN}`, { username, password, deviceId });
    return response
  };
}
